/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import React, { useState, useEffect, useContext } from "react";
import Head from "next/head";
import { useQuery } from "@apollo/client";
//import isEmpty from "lodash";
import Router from "next/router";
import NProgress from "nprogress";
import Header from "./Header";
//import dynamic from "next/dynamic";

import GET_CART from "../queries/get-cart";
import { AppContext } from "./context/AppContext";
import { getFormattedCart } from "../functions";
import Cookies from "./cookie/cookies";
import Footer from "./NewFooter";
import WhatsappWidget from "./WhatsappWidget";

Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

//const Cookies = dynamic(() => import("./cookie/cookies"), { ssr: false });
//const Publicidad = dynamic(() => import("./popup"), { ssr: false });
//const Footer = dynamic(() => import("./Footer"), { ssr: false });

const Layout = (props) => {

  const [cart, setCart] = useContext(AppContext);
  const [showCookies, setShowCookies] = useState(false);
  // const [showPubli, setShowPubli] = useState(false);

  // Get Cart Data.
  const { loading: loadCart, error: errorCart, data: dataCart, refetch } = useQuery(GET_CART, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      // Update cart in the localStorage.
      const updatedCart = getFormattedCart(data);
      if (typeof window !== "undefined") {
        localStorage.setItem("classic-cart", JSON.stringify(updatedCart));
      }
      // Update cart data in React Context.
      setCart(updatedCart);
    },
    onError: (error) => {
      if (error) {
        const errorMessage = error?.graphQLErrors?.[0]?.message ? error.graphQLErrors[0].message : "";
        if (errorMessage == "Expired token") {
          if (typeof window !== "undefined") {
            var cart = localStorage.getItem("classic-cart");
            if (cart) localStorage.setItem("classic-cart", null);
            localStorage.removeItem("woo-session");
          }
        }
      }
    }
  });

  useEffect(() => {
    if (typeof window !== "undefined") {
      const ck = localStorage.getItem("cookies");
      if (!ck) {
        setShowCookies(true);
      }
    }
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const ck = localStorage.getItem("cookies");
      if (ck) {
        let isShow = JSON.parse(ck);
        let currentDate = new Date();
        let CurrentMonth = currentDate.getMonth() + 1;
        let CurrentDay = currentDate.getDate();

        let today = CurrentMonth + CurrentDay;
        if (isShow && isShow.date < today) isShow.showed = false;
        // if (isShow && isShow.showed === true)
        //   setShowPubli(false);
        // else setShowPubli(true);
      }
    }
  }, [showCookies]);

  /*
  var keywords = "";
  if (!isEmpty(tags))
    tags?.map(function (item) {
      keywords = keywords + item.name + " ";
    });*/

  let urlRelativa = props?.urlPage ? props?.urlPage : "";

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,viewport-fit=cover"
        />
        <meta name="description" content={`${props?.seo?.title} | ${props?.seo?.description}`} />
        <meta name="facebook-domain-verification" content="8rrmjjn1r2bf1vzuh2foyu1ysnp1j0" />

        <title>{props?.seo?.title || 'Classic Jeans'}</title>
        <link rel="canonical" href={props?.setting?.urlFront + urlRelativa} />
        <link rel="icon" href="/favicon.ico" type="image/x-icon"/>
        <link rel="icon" href="/favicon.png" type="image/png"/>
        <link rel="icon" href="/favicon.svg" type="image/svg+xml"/>


        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={props?.seo?.title} />
        <meta property="og:description" content={props?.seo?.description} />
        <meta property="og:image" content={props?.seo?.image || "https://res.cloudinary.com/dl404fcuc/image/upload/v1727813231/classicjeans-logo.png"} />
        <meta property="og:url" content={props?.setting?.urlFront + urlRelativa} />
        <meta property="og:type" content="website" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={props?.seo?.title} />
        <meta name="twitter:description" content={props?.seo?.description} />
        <meta name="twitter:image" content={props?.seo?.image || "https://res.cloudinary.com/dl404fcuc/image/upload/v1727813231/classicjeans-logo.png"} />

        {/* Additional Meta Tags */}
        <meta name="keywords" content={`${props?.seo?.title}, ${props?.seo?.keywords}`} />
      </Head>

      {props?.setting?.cookiesPolice ?
        <Cookies
          open={showCookies}
          setOpen={setShowCookies}
          anuncio={props?.setting?.cookiesPolice}
          urlToReload={props?.setting?.urlFront + urlRelativa}
        />
        : ""
      }
      {/* {showPubli === true ?
        <Publicidad
          open={showPubli}
          setOpen={setShowPubli}
          promo={bienvenida}
        />
        : ""
      } */}

      <Header
        categories={props?.categories}
        menu={props?.menu}
        stickyMenu={props?.stickyMenu}
        promo={props?.setting}
      />
      {props.children}
      <WhatsappWidget />
      {props.urlPage != "/confirmacion-pedido" ?
        <Footer footerMenu={props?.footerMenu} />
        : <></>}
    </>
  );
};

export default Layout;